<template>
  <div>
    <div class="d-flex justify-content-between">
      <h1>Scenario: {{ this.$route.params.type }}</h1>
      <div>
        <router-link
          class="btn btn-red py-1 px-4 mr-3"
          :to="{ name: 'peno.scenarios' }"
        >
          <eva-icon class="eva-red" name="arrow-back-outline"></eva-icon>
          ย้อนกลับ (ภาพรวมจำลองสถานการณ์)
        </router-link>
      </div>
    </div>
    <h2>Energy Demand</h2>
    <div id="demand-container"></div>
    <h2 class="mt-4">Energy Supply</h2>
    <h5>ปริมาณอุปทานพลังงานในกรณีที่มีการใช้พลังงานทดแทนในสัดส่วนต่าง ๆ</h5>
    <div class="d-flex">
      <div class="legend">
        <div class="legend-icon" style="background: #d5d1ae"></div>
        <span>ก๊าซธรรมชาติ</span>
      </div>
      <div class="legend">
        <div class="legend-icon" style="background: #3a393c"></div>
        <span>ถ่านหิน</span>
      </div>
      <div class="legend">
        <div class="legend-icon" style="background: #8ac957"></div>
        <span>พลังงานชีวภาพ</span>
      </div>
      <div class="legend">
        <div class="legend-icon" style="background: #f5e555"></div>
        <span>พลังงานแสงอาทิตย์</span>
      </div>
      <div class="legend">
        <div class="legend-icon" style="background: #3d48e5"></div>
        <span>พลังงานลม</span>
      </div>
      <div class="legend">
        <div class="legend-icon" style="background: #a0f3ed"></div>
        <span>ไฟฟ้า (นำเข้า)</span>
      </div>
      <div class="legend">
        <div class="legend-icon" style="background: #903290"></div>
        <span>พลังงานจากขยะ</span>
      </div>
    </div>
    <div class="position-relative" style="padding-left: 50px">
      <div id="supply1-container" class="mt-2"></div>
      <div
        class="position-absolute"
        style="left: -100px; top: 50%; transform: rotate(-90deg)"
      >
        ปริมาณอุปสงค์และอุปทานด้านพลังงาน (ktoe)
      </div>
      <div class="container">
        <div class="row">
          <div class="" style="width: 10%"></div>
          <div
            class="d-flex justify-content-center"
            v-for="(scenarioObject, index) in linksToScenarioDetail"
            :key="index"
            v-bind:style="{ width: 87 / scenarioCount + '%' }"
          >
            <router-link
              :to="{
                name: 'peno.scenarios.scenario_detail',
                params: {
                  scenario: scenarioObject.scenario,
                  type: $route.params.type,
                },
              }"
              class="btn btn-outline-red"
              >{{ scenarioObject.title }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <h5 class="mt-4">
      มูลค่าพลังงานในกรณีที่มีการใช้พลังงานทดแทนในสัดส่วนต่าง ๆ
    </h5>
    <div class="position-relative" style="padding-left: 30px">
      <div id="supply2-container" class="mt-2"></div>
      <div
        class="position-absolute"
        style="left: -50px; top: 50%; transform: rotate(-90deg)"
      >
        มูลค่าพลังงาน (พันบาท)
      </div>
    </div>
    <h5 class="mt-4">
      ปริมาณการปล่อยก๊าซเรือนกระจกในกรณีที่มีการใช้พลังงานทดแทนในสัดส่วนต่าง ๆ
    </h5>
    <div class="position-relative" style="padding-left: 30px">
      <div id="supply3-container" class="mt-2"></div>
      <div
        class="position-absolute"
        style="left: -100px; top: 50%; transform: rotate(-90deg)"
      >
        ปริมาณการปล่อยก๊าซเรือนกระจก (tCO2e)
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'scenarios.demand-case-detail',
  data() {
    return {
      scenarioCount: 0,
      links: [false, false, false, false, false, false],
    };
  },
  mounted() {
    // console.log(this.$route.params.type);
    this.fetchGraphs();
  },
  computed: {
    ...mapState('peno', ['currentReport']),
    ...mapGetters('peno', ['currentProvince']),
    linksToScenarioDetail() {
      let result = [];
      if (this.$route.params.type == 'BAU') {
        // return ['P001', 'P002', 'P003', 'P004', 'P005', 'P006'].slice(0, this.scenarioCount);
        let defaultLinks = [
          { scenario: 'P001', title: 'Min Cost.' },
          { scenario: 'P002', title: '20%' },
          { scenario: 'P003', title: '40%' },
          { scenario: 'P004', title: '60%' },
          { scenario: 'P005', title: '80%' },
          { scenario: 'P006', title: '100%' },
        ];
        for (let i = 0; i < 6; i++) {
          if (this.links[i]) {
            result.push(defaultLinks[i]);
          }
        }
      }
      if (this.$route.params.type == 'EE') {
        // return ['P007', 'P008', 'P009', 'P010', 'P011', 'P012'].slice(0, this.scenarioCount);
        // let defaultLinks = ['P007', 'P007', 'P009', 'P010', 'P011', 'P012'];
        let defaultLinks = [
          { scenario: 'P007', title: 'Min Cost.' },
          { scenario: 'P008', title: '20%' },
          { scenario: 'P009', title: '40%' },
          { scenario: 'P010', title: '60%' },
          { scenario: 'P011', title: '80%' },
          { scenario: 'P012', title: '100%' },
        ];
        for (let i = 0; i < 6; i++) {
          if (this.links[i]) {
            result.push(defaultLinks[i]);
          }
        }
      }
      if (this.$route.params.type == 'EEPLUS') {
        // return ['P013', 'P014', 'P015', 'P016', 'P017', 'P018'].slice(0, this.scenarioCount);
        // let defaultLinks = ['P013', 'P014', 'P015', 'P016', 'P017', 'P018'];
        let defaultLinks = [
          { scenario: 'P013', title: 'Min Cost.' },
          { scenario: 'P014', title: '20%' },
          { scenario: 'P015', title: '40%' },
          { scenario: 'P016', title: '60%' },
          { scenario: 'P017', title: '80%' },
          { scenario: 'P018', title: '100%' },
        ];
        for (let i = 0; i < 6; i++) {
          if (this.links[i]) {
            result.push(defaultLinks[i]);
          }
        }
      }
      return result;
    },
  },
  methods: {
    ...mapActions('mondas', [
      'fetchScenarioEnergyDemand',
      'fetchScenarioEnergySupply1',
      'fetchScenarioEnergySupply2',
      'fetchScenarioEnergySupply3',
    ]),
    replaceAll(str, find, replace) {
      return str.replace(new RegExp(find, 'g'), replace);
    },
    fetchGraphs() {
      this.fetchScenarioEnergyDemand({
        province: this.currentProvince,
        group: this.$route.params.type,
      }).then((demandGraph) => {
        if (demandGraph) {
          let demandGraphSvgContainer =
            document.getElementById('demand-container');
          demandGraph = this.replaceAll(
            demandGraph,
            'font-size="11"',
            'font-size="22"'
          );
          demandGraph = this.replaceAll(
            demandGraph,
            'font-size="10"',
            'font-size="20"'
          );
          demandGraph = this.replaceAll(
            demandGraph,
            'font-size="9"',
            'font-size="18"'
          );
          demandGraphSvgContainer.innerHTML = demandGraph;
        }
      });
      this.fetchScenarioEnergySupply1({
        province: this.currentProvince,
        group: this.$route.params.type,
      }).then((supply1) => {
        if (supply1) {
          let supplyGraph1SvgContainer =
            document.getElementById('supply1-container');
          supply1 = this.replaceAll(
            supply1,
            'font-size="11"',
            'font-size="22"'
          );
          supply1 = this.replaceAll(
            supply1,
            'font-size="10"',
            'font-size="20"'
          );
          supply1 = this.replaceAll(supply1, 'font-size="9"', 'font-size="22"');
          supplyGraph1SvgContainer.innerHTML = supply1;
          // count graph
          // this.supplyCount('supply1-container');
          this.filteredLink(supply1);
        }
      });
      this.fetchScenarioEnergySupply2({
        province: this.currentProvince,
        group: this.$route.params.type,
      }).then((supply2) => {
        if (supply2) {
          let supplyGraph2SvgContainer =
            document.getElementById('supply2-container');
          supply2 = this.replaceAll(
            supply2,
            'font-size="11"',
            'font-size="22"'
          );
          supply2 = this.replaceAll(
            supply2,
            'font-size="10"',
            'font-size="20"'
          );
          supply2 = this.replaceAll(supply2, 'font-size="9"', 'font-size="22"');
          supplyGraph2SvgContainer.innerHTML = supply2;
        }
      });
      this.fetchScenarioEnergySupply3({
        province: this.currentProvince,
        group: this.$route.params.type,
      }).then((supply3) => {
        if (supply3) {
          let supplyGraph3SvgContainer =
            document.getElementById('supply3-container');
          supply3 = this.replaceAll(
            supply3,
            'font-size="11"',
            'font-size="22"'
          );
          supply3 = this.replaceAll(
            supply3,
            'font-size="10"',
            'font-size="20"'
          );
          supply3 = this.replaceAll(supply3, 'font-size="9"', 'font-size="22"');
          supplyGraph3SvgContainer.innerHTML = supply3;
        }
      });
    },
    // supplyCount(elementId) {
    //   let element = document.getElementById(elementId);
    //   let svg = element.childNodes[0];
    //   let nodes = svg.childNodes;
    //   let count = 0;
    //   let isRect = false;
    //   for (let i = 0; i < nodes.length; i++) {
    //     if (!isRect && nodes[i].tagName == 'rect') {
    //       count++;
    //       isRect = true;
    //     }
    //     if (isRect && nodes[i].tagName == 'text') {
    //       isRect = false;
    //     }
    //   }
    //   this.scenarioCount = count;
    // },
    filteredLink(svgText) {
      // let element = document.getElementById(elementId);
      // let svg = element.childNodes[0];
      // let nodes = svg.childNodes;
      let linksExist = [false, false, false, false, false, false];

      if (svgText.includes('Min. Cost')) {
        linksExist[0] = true;
        this.scenarioCount++;
      }
      if (svgText.includes('20%')) {
        linksExist[1] = true;
        this.scenarioCount++;
      }
      if (svgText.includes('40%')) {
        linksExist[2] = true;
        this.scenarioCount++;
      }
      if (svgText.includes('60%')) {
        linksExist[3] = true;
        this.scenarioCount++;
      }
      if (svgText.includes('80%')) {
        linksExist[4] = true;
        this.scenarioCount++;
      }
      if (svgText.includes('100%')) {
        linksExist[5] = true;
        this.scenarioCount++;
      }
      this.links = linksExist;
    },
  },
};
</script>

<style scoped>
.legend {
  font-size: 0.8rem;
  margin-right: 14px;
}
.legend-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  margin-right: 6px;
}
</style>
